import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  PageWrapper,
  TextWrapper,
  HeadingWrapper,
  FlexWrapper,
  FlexItem,
} from "../components/contentwrappers"
import ContactForm from "../components/contact-form/contact-form"
import { FaBuilding, FaPhone, FaEnvelope } from "react-icons/fa"

const ImportantInformationPage = props => (
  <>
    <BackgroundImage
      fluid={props.data.royalCourtsImage.childImageSharp.fluid}
      alt="The Royal Courts of Justice"
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "-9",
      }}
    ></BackgroundImage>

    <Layout>
      <SEO title="Important Information" />
      <PageWrapper>
        <HeadingWrapper>
          <h1>
            <strong>Important</strong> Information
          </h1>
        </HeadingWrapper>

        <TextWrapper>
          <h2>
            <strong>Risk</strong> Warning
          </h2>

          <p>
            This website is designed to provide information about Law Society
            (NI) Financial Advice Ltd. Any reference to investment products are
            for educational purposes and should not be considered investment
            advice or an offer of any security for sale. Such references do not
            represent a recommendation of any particular security, strategy or
            investment product. Information contained on this website has been
            obtained from sources believed to be reliable, but is not
            guaranteed.
          </p>

          <p>
            Please be aware that the value of investments and any income will
            fluctuate (this may partly be the result of exchange rate
            fluctuations) and investors may not get back the full amount
            invested.
          </p>

          <p>
            Past performance is not a guide to future returns. Current tax
            levels and reliefs may change. Depending on individual
            circumstances, this may affect investment returns.
          </p>
        </TextWrapper>

        <TextWrapper>
          <h2>
            <strong>Privacy</strong> Policy
          </h2>

          <p>
            At Law Society (NI) Financial Advice Ltd (LSNIFA), we’re committed
            to protecting and respecting your privacy. By using our website,
            you’re agreeing to be bound by this Policy.
          </p>

          <p>
            Our Privacy Policy explains when and why we collect personal
            information about people who visit our website, how we use it, the
            conditions under which we may disclose it to others and how we keep
            it secure.
          </p>

          <p>
            LSNIFA acts as a controller for the personal information you
            provide.
          </p>

          <p>
            For more information, please check our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </p>
        </TextWrapper>

        <TextWrapper>
          <h2>
            <strong>Complaints</strong> Policy
          </h2>

          <p>
            In the event that you ever have to complain, you should contact: The
            compliance officer at Stonyroyd House, 8 Cumberland Road, Leeds,
            West Yorkshire, LS6 2EF or by phone 0113 224 2800.
          </p>

          <p>
            For more information, please check our{" "}
            <Link to="/complaints-policy">Complaints Policy</Link>.{" "}
          </p>
        </TextWrapper>

        <TextWrapper id="fos">
          <h2>
            <strong>Financial </strong> Ombudsman Service
          </h2>

          <p>
            The Financial Ombudsman Service is available to sort out individual
            complaints that clients and financial services businesses aren’t
            able to resolve themselves.
          </p>

          <p>
            To contact the Financial Ombudsman Service please visit{" "}
            <a
              href="https://www.financial-ombudsman.org.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.financial-ombudsman.org.uk
            </a>
            .{" "}
          </p>
        </TextWrapper>

       

        <TextWrapper>
          <h2>
            <strong>Contact</strong> Us
          </h2>

          <ContactForm></ContactForm>
          <FlexWrapper>
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}>
                  <FaBuilding style={{ marginRight: "1em" }} />
                  Address
                </strong>{" "}
                1st Floor, Avenue House <br /> 42-44 Rosemary Street <br />{" "}
                Belfast, BT1 1QE
              </div>
            </FlexItem>
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}>
                  <FaEnvelope style={{ marginRight: "1em" }} />
                  Email
                </strong>{" "}
                <a style={{ color: "white" }} hRef="mailTo:info@lsnifa.com">
                  info@lsnifa.com
                </a>
              </div>
            </FlexItem>
            <FlexItem flexMe="1.5">
              <div style={{ textAlign: "center" }}>
                <strong style={{ display: "block" }}>
                  <FaPhone style={{ marginRight: "1em" }} />
                  Phone
                </strong>{" "}
                028 9023 0696
              </div>
            </FlexItem>
          </FlexWrapper>
        </TextWrapper>
      </PageWrapper>
    </Layout>
  </>
)

export default ImportantInformationPage

export const pageQuery = graphql`
  query {
    royalCourtsImage: file(
      relativePath: { eq: "royal-courts-of-justice.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 5031, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
